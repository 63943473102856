/**
* Generated automatically at built-time (2025-04-01T11:08:12.447Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "2009df34-086d-4064-8555-42d957c3bff0",templateKey: "sites/101-4c75b23f-098d-44c2-8b25-8921c3f7f839"};